<template>
  <div class="white--text grey lighten-3 pa-0" fluid>
    <simple-header
      hasLeftChild
      :routes="[{ title: 'خانه', url: '/' }, { title: 'پرداخت' }]"
      title="پرداخت"
    >
      <v-img
        :src="require('../../assets/icons/credit-card.png')"
        class="sp-img white-icon mr-md-auto ml-md-0 mx-auto"
      />
    </simple-header>
    <v-container>
      <v-card class="rounded-xl moholand-shadow">
        <v-card-text class="pa-8 black--text">
          <v-row justify="center">
            <v-col cols="12" md="7">
              <v-btn
                block
                v-if="!$store.state.userData"
                elevation="0"
                color="orange darken-1"
                dark
                link
                to="/login?checkout=true"
                large
                class="rounded-lg"
              >
                اگر قبلا توی سایت ثبت نام کردی اینجا کلیک کن!
              </v-btn>
              <v-form class="mt-4">
                <div v-if="!$store.state.userData">
                  <h3>نام و نام خانوادگی</h3>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details
                    solo
                    flat
                    class="rounded-lg"
                    v-model="model.name"
                  />
                </div>
                <div class="mt-4" v-if="!$store.state.userData">
                  <h3>ایمیل شما</h3>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details
                    solo
                    flat
                    class="rounded-lg"
                    v-model="model.email"
                  />
                </div>
                <div class="mt-4" v-if="!$store.state.userData">
                  <h3>شماره موبایل</h3>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details
                    solo
                    flat
                    class="rounded-lg"
                    v-model="model.cellphone"
                  />
                </div>
                <div class="mt-4">
                  <h3>کد تخفیف</h3>
                  <v-row justify="space-between" align="center">
                    <v-col cols="12" lg="6" md="4">
                      <v-text-field
                        background-color="grey lighten-2"
                        hide-details
                        solo
                        flat
                        class="rounded-lg"
                        v-model="model.discount"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" md="4">
                      <v-btn
                        elevation="0"
                        color="orange darken-1"
                        dark
                        large
                        @click="discountSubmit"
                        block
                        class="rounded-lg"
                        :loading="loading || loadingCart"
                      >
                        استفاده از تخفیف
                      </v-btn>
                    </v-col>
                    <v-col cols="12" lg="3" md="4">
                      <v-btn
                        elevation="0"
                        color="orange darken-1"
                        dark
                        large
                        block
                        class="rounded-lg px-0"
                        :loading="loading || loadingCart"
                      >
                        از کیف پولم کم کن
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-form>

              <my-data-table
                :headers="headers"
                :items="$store.state.cart.products || []"
                :loading="loadingCart"
                :hasButton="false"
                :paginationEnabled="false"
                class="rounded-xl mt-4 elevation-3"
              >
                <template #contentTag="{ row }">
                  <td class="text-center">
                    {{
                      row.product
                        ? 'محصول'
                        : row.course
                        ? 'دوره'
                        : row.scenario
                        ? 'داستان'
                        : '-'
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      row.product
                        ? row.product.name
                        : row.course
                        ? row.course.title
                        : row.scenario
                        ? row.scenario.title
                        : '-'
                    }}
                  </td>
                  <!-- <td class="text-center">
                    {{ row.sellername }}
                  </td> -->
                  <td class="text-center">
                    {{
                      new Intl.NumberFormat().format(
                        row.offer_price ? row.offer_price : row.price || 0
                      ) + ' تومان'
                    }}
                  </td>
                  <td>
                    <v-btn
                      :color="loading ? 'grey' : 'error'"
                      fab
                      x-small
                      elevation="0"
                      class="rounded-lg my-auto"
                      @click="onDeleteClick(row)"
                      :disabled="loading"
                    >
                      <v-icon size="25"> mdi-close </v-icon>
                    </v-btn>
                  </td>
                </template>
              </my-data-table>

              <v-row class="mt-4">
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="model.isLawAccepted"
                    label="قوانین سایت را قبول دارم"
                  >
                    <template #label>
                      <div class="label">
                        <a @click="openRules">قوانین</a> سایت را خوانده ام و
                        قبول دارم.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card class="rounded-lg elevation-3 text-center">
                    <v-card-text>
                      <h2 class="orange--text darken-2">
                        مجموع:
                        {{
                          new Intl.NumberFormat().format(
                            $store.state.cart.total_price || 0
                          ) + ' تومان'
                        }}
                      </h2>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <div
                class="text-center mt-4"
                v-if="$store.state.cart.total_price > 0"
              >
                روش پرداخت را انتخاب کن:
              </div>
              <v-row
                class="mt-4"
                align="center"
                justify="center"
                v-if="$store.state.cart.total_price > 0"
              >
                <v-col md="3" sm="4" cols="6">
                  <v-card
                    class="pay-way rounded-xl elevation-3 text-center ma-auto"
                    :class="model.payment_type == 0 && 'orange lighten-1'"
                    @click="model.payment_type = 0"
                  >
                    <v-card-text>
                      <v-img
                        :src="require('../../assets/img/zarinpal.jpg')"
                        class="sp-img mr-md-auto ml-md-0 mx-auto"
                      />
                    </v-card-text>
                  </v-card>
                  <h4 class="text-center mt-4">زرین پال</h4>
                </v-col>
                <v-col md="3" sm="4" cols="6">
                  <v-card
                    class="pay-way rounded-xl elevation-3 text-center ma-auto"
                    :class="model.payment_type == 1 && 'orange lighten-1'"
                    @click="model.payment_type = 1"
                  >
                    <v-card-text>
                      <v-img
                        :src="require('../../assets/img/payping.png')"
                        class="sp-img mr-md-auto ml-md-0 mx-auto"
                      />
                    </v-card-text>
                  </v-card>
                  <h4 class="text-center mt-4">پی پینگ</h4>
                </v-col>
              </v-row>

              <v-btn
                v-if="$store.state.cart.total_price > 0"
                block
                elevation="0"
                color="success"
                dark
                large
                class="rounded-lg mt-8"
                :loading="loading"
                :disabled="!$store.state.cart.products.length"
                @click="submitOrder"
              >
                پرداخت نهایی
              </v-btn>
              <v-btn
                v-else
                block
                elevation="0"
                color="success"
                dark
                large
                class="rounded-lg mt-8"
                :loading="loading"
                :disabled="!$store.state.cart.products.length"
                @click="submitFreeOrder"
              >
                تکمیل خرید و دانلود
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MyAxios from '@/constants/MyAxios';
import SimpleHeader from '../../components/SimpleHeader.vue';
import MyDataTable from '../../components/MyDataTable.vue';
import { getErrorText } from '@/constants/GlobalConstants';

export default {
  name: 'Payment',
  components: {
    SimpleHeader,
    MyDataTable,
  },
  data() {
    return {
      payment: this.$route.params.payment || '',
      model: {
        name: '',
        cellphone: '',
        email: '',
        discount: '',
        isLawAccepted: 0,
        payment_type: 0,
      },
      loading: false,
      loadingCart: false,
      headers: [
        { text: 'نوع', align: 'center', value: 'category.name' },
        {
          text: 'فایل / دوره',
          align: 'center',
          value: 'title',
        },
        // { text: "فروشنده", align: "center", value: "category.name" },
        { text: 'قیمت', align: 'center', value: 'category.name' },
        { text: 'عملیات', value: 'actions', sortable: false },
      ],
    };
  },
  mounted() {
    this.$root.$emit('getCart', loadingCart => {
      this.loadingCart = loadingCart;
    });
  },
  methods: {
    discountSubmit() {
      this.loading = true;

      MyAxios.post('carts/' + this.$store.state.cart?.id + '/apply/discount', {
        code: this.model.discount,
        user_id: this.$store.state?.userData?.id
          ? this.$store.state?.userData?.id
          : undefined,
      })
        .then(response => {
          this.loading = false;
          this.$root.$emit('getCart', loadingCart => {
            this.loadingCart = loadingCart;
          });
          this.$root.$emit('toast', {
            text: response.data.msg,
            type: 'success',
          });
        })
        .catch(error => {
          this.loading = false;
          this.$root.$emit('toast', {
            text: error.response.data.msg,
            type: 'error',
          });
        });
    },
    submitOrder() {
      if (!this.model.isLawAccepted) {
        this.$root.$emit('toast', {
          text: 'برای خرید از سایت باید با قوانین سایت موافق باشید.',
        });
        return;
      }

      if (!this.loading) this.loading = true;
      let formData = new FormData();
      formData.append('user_id', this.$store.state.userData?.id);
      formData.append('payment_type', this.model.payment_type);
      formData.append('cart_id', this.$store.state.cart?.id);

      MyAxios.post('orders/submit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 400000,
      })
        .then(response => {
          this.loading = false;
          window.location.href = response.data.action;
          this.$root.$emit('toast', {
            text: 'در حال انتقال به درگاه پرداخت',
            type: 'success',
          });
          this.commentModal = false;
        })
        .catch(error => {
          this.loading = false;
          this.$root.$emit('toast', {
            text: getErrorText(error),
          });
        });
    },
    openRules() {
      window.open('/page/rules', '_blank');
      // this.$router.push("/page/rules");
    },
    submitFromWallet() {
      if (!this.model.isLawAccepted) {
        this.$root.$emit('toast', {
          text: 'برای خرید از سایت باید با قوانین سایت موافق باشید.',
        });
        return;
      }

      if (!this.loading) this.loading = true;
      let formData = new FormData();
      formData.append('user_id', this.$store.state.userData.id);
      formData.append('payment_type', 3);
      formData.append('cart_id', this.$store.state?.cart?.id);

      MyAxios.post('orders/submit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 400000,
      })
        .then(response => {
          this.$root.$emit('removeCart');
          this.$root.$emit('toast', {
            text: ' خرید شما با موفقیت تکمیل شد.',
            type: 'success',
          });
          this.$router.replace('/mydownloads');
          this.commentModal = false;
        })
        .catch(error => {
          this.loading = false;
          this.$root.$emit('toast', {
            text: getErrorText(error),
          });
        });
    },
    submitFreeOrder() {
      if (!this.model.isLawAccepted) {
        this.$root.$emit('toast', {
          text: 'برای خرید از سایت باید با قوانین سایت موافق باشید.',
        });
        return;
      }
      if (!this.loading) this.loading = true;
      if (this.$store.state.cart.total_price !== 0) {
        this.$root.$emit('toast', {
          text: 'خرید شما مجاز نیست!لطفا با پشتیبانی تماس بگیرید!',
        });
      } else {
        let formData = new FormData();
        formData.append('user_id', this.$store.state.userData.id);
        formData.append('cart_id', this.$store.state.cart.id);

        MyAxios.post('orders/submit', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 400000,
        })
          .then(response => {
            this.$root.$emit('removeCart');
            this.$root.$emit('toast', {
              text: ' خرید شما با موفقیت تکمیل شد.',
              type: 'success',
            });
            this.$router.replace('/mydownloads');
            this.commentModal = false;
          })
          .catch(error => {
            this.loading = false;
            this.$root.$emit('toast', {
              text: getErrorText(error),
            });
          });
      }
    },
    onDeleteClick(item) {
      this.loading = true;
      this.$root.$emit('removeFromCart', item.id, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.ico-success {
  box-shadow: 0 0 0px 7px #92da97, 0 0 4px 13px #cefbd0;
}
.error {
  box-shadow: 0 0 0px 7px #fd7373, 0 0 4px 13px #fbcece;
}
.ltr {
  direction: ltr;
}
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 150px;
  max-height: 100px;
}
.pay-way {
  max-width: 100px;
  aspect-ratio: 1;
}
</style>
